const topMain = () => {


  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {

    const jsSecMain = document.getElementById('jsSecMain');
    const mainTxt = jsSecMain.querySelector('.js-main-txt');

    if (mainTxt != null) {
      let txt = mainTxt.children;
      let accent = jsSecMain.querySelectorAll('.js-accent');

      gsap.set(txt, {
        opacity: 0,
        filter: "blur(10px)",
      });

      gsap.to(txt, {
        opacity: 1,
        filter: "blur(0px)",
        duration: 1.0,
        delay: 0.5,
        stagger: {
          each: 0.1,
          from: "random",
        },
      });

      setTimeout(() => {
        accent[0].classList.add('act');
      }, 800)
      setTimeout(() => {
        accent[1].classList.add('act');
      }, 1400)
      setTimeout(() => {
        accent[2].classList.add('act');
      }, 2000)
    }

  }
}
export default topMain;