const topParallax = (() => {

  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {

    let sW = window.innerWidth;
    let trigger = document.querySelectorAll('.js-parallax-trigger')
    let parallax = document.querySelectorAll('.js-parallax')
    let y
    // console.log("object");

    if (sW > 750) {
      y = 220;
    } else {
      y = 80;
    }

    for (let i = 0; i < trigger.length; i++) {
      gsap.to(parallax[i], {
        y: y,
        scrollTrigger: {
          trigger: trigger[i],
          start: 'top bottom',
          end: 'bottom top',
          scrub: 0.2,
          markers: true
        }
      })
    }
  }

})();
export default topParallax;