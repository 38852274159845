const funcHeader = (() => {

  const pageTop = document.getElementById('pageTop');

  if (pageTop) {
    let nav = document.getElementById('jsNav');
    let navList = nav.querySelectorAll('.js-list');
    let jsTrigger = pageTop.querySelectorAll('.js-trigger');

    for (let i = 0; i < navList.length; i++) {
      navList[i].addEventListener("click", () => {

        // // 押されたiとjが同じだったらactとらない
        for (let j = 0; j < navList.length; j++) {
          if (!(j == i)) {
            // console.log(j);
            navList[j].classList.remove("act");
          }
        }

        navList[i].classList.add('act');
      });
    }


    const secMain = document.getElementById('jsSecMain');
    const secWork = document.getElementById('jsSecWork');
    const secProfile = document.getElementById('jsSecProfile');

    ScrollTrigger.create({
      trigger: secMain,
      start: '0% 1%',
      endTrigger: 'body',
      end: '100% 0%',
      toggleClass: {
        targets: nav,
        className: "act-ank-1"
      },
      // markers: true,
    });
    ScrollTrigger.create({
      trigger: secWork,
      start: 'top 80%',
      endTrigger: 'body',
      end: '100% 0%',
      toggleClass: {
        targets: nav,
        className: "act-ank-2"
      },
      // markers: true,
    });
    ScrollTrigger.create({
      trigger: secProfile,
      start: 'top+=20% 80%',
      endTrigger: 'body',
      end: '100% 0%',
      toggleClass: {
        targets: nav,
        className: "act-ank-3"
      },
      // markers: true,
    });


    // for (let i = 0; i < jsTrigger.length; i++) {

    //   gsap.to(navList[i], {
    //     scrollTrigger: {
    //       trigger: jsTrigger[i],
    //       start: '0% 50%',
    //       endTrigger: jsTrigger[i],
    //       end: 'bottom 50%',
    //       onEnter: () => {
    //         navList[i].classList.add('act')
    //         console.log("onEnter");
    //       },
    //       toggleClass: {
    //         targets: navList[i],
    //         className: 'act',
    //       },
    //       // markers: true,
    //     }
    //   });
    // }
  }



})();
export default funcHeader;