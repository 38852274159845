const comBg = (() => {

  const comBg = (document.getElementById("jsSecCover") != null);
  if (comBg) {

    VANTA.FOG({
      el: "#jsSecCover",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      highlightColor: 0xfff1da,
      // highlightColor: 0xffeed2,
      midtoneColor: 0xffffff,
      lowlightColor: 0xffffff,
      baseColor: 0xffffff,
      blurFactor: 0.6,
      speed: 3.8,
      zoom: 0.80
    })

  }

})();
export default comBg;