export { default as funcUserAgent } from './funcUserAgent';
export { default as funcWindowResize } from './funcWindowResize';
export { default as funcScrollControl } from './funcScrollControl';
export { default as funcScrollAnchor } from './funcScrollAnchor';
// export { default as funcNav } from './funcNav';
export { default as funcHeader } from './funcHeader';
export { default as funcLoadImg } from './funcLoadImg';
export { default as classDistortion } from './classDistortion';
export { default as funcLoad } from './funcLoad';


export { default as comFade } from './comFade';
export { default as comImgFade } from './comImgFade';
export { default as comTxt } from './comTxt';
export { default as comBg } from './comBg';

export { default as topMain } from './topMain';
export { default as topParallax } from './topParallax';
// export { default as topAnime } from './topAnime';


// export { default as sampleDistortion } from './sampleDistortion';
// export { default as loadImgSample } from './loadImgSample';


export { default as funcEntry } from './funcEntry';